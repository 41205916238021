<template>
  <v-dialog
    max-width="1000px"
    v-model="dialog"
    scrollable
    :fullscreen="fullscreen"
    @keydown.esc="dialog = false"
  >
    <v-card :loading="loading">
      <v-system-bar window dark
        ><span>Personenkonto</span><v-spacer />
        <v-btn icon v-if="fullscreen" @click="fullscreen = false"
          ><v-icon>mdi-window-restore</v-icon></v-btn
        >
        <v-btn v-else icon @click="fullscreen = true"
          ><v-icon>mdi-window-maximize</v-icon></v-btn
        >
        <v-btn icon @click="dialog = false"
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-system-bar
      >

      <v-toolbar color="info" flat dark>
        <PersonItem :value="overview.person" />
        <template v-if="cantonal === 0">
          <span class="text-caption">Beitrag Kanton</span>
          <v-chip light
            ><CurrencyValue :value="overview.balanceCantonal" />
          </v-chip>
        </template>
        <template v-else>
          <span class="text-caption">Eigene Kosten</span>
          <v-chip light>
            <CurrencyValue light :value="overview.balanceOwn" />
          </v-chip>
        </template>
        <v-dialog v-model="newDialog" width="500" persistent>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              small
              class="ml-2"
              fab
              color="success"
              v-bind="attrs"
              v-on="on"
              ><v-icon>mdi-plus</v-icon></v-btn
            >
          </template>

          <v-card>
            <v-system-bar window>Transaktion hinzufügen</v-system-bar>
            <v-card-text class="mt-4 pb-2">
              <DateInput label="Datum" v-model="newItem.date" />
              <LookupValueInput
                label="Buchungsart"
                v-model="newItem.type"
                resource="finance/transactiontype"
              ></LookupValueInput>
              <v-text-field autofocus label="Anlass" v-model="newItem.event" />
              <v-text-field
                label="Beschreibung"
                v-model="newItem.description"
              />
              <v-text-field
                label="Rechnungsnummer"
                v-model="newItem.invoiceNumber"
                counter="20"
              />
              <v-text-field
                @keydown.enter="saveNew"
                type="number"
                label="Betrag"
                v-model="newItem.amount"
              />
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn text @click="newDialog = false">abbrechen</v-btn
              ><v-btn
                text
                color="primary"
                :disabled="!saveable"
                :loading="saving"
                @click="saveNew"
                >speichern</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-toolbar flat>
        <DateInput
          label="von"
          v-model="startDate"
          hideDetails
          class="mr-2"
          clearable
        ></DateInput>
        <DateInput
          label="bis"
          v-model="endDate"
          hideDetails
          class="mx-2"
          clearable
        ></DateInput>
        <v-btn
          class="mr-2"
          @click="
            startDate = '';
            endDate = '';
          "
          text
          >alle</v-btn
        >
        <v-spacer></v-spacer>

        <v-switch inset hide-details label="Anlass" v-model="event"></v-switch>
        <template v-slot:extension>
          <v-tabs
            v-model="cantonal"
            light
            background-color="white"
            color="info"
            centered
            ><v-tab>Beitrag Kanton</v-tab><v-tab>Eigene Kosten</v-tab></v-tabs
          >
        </template>
      </v-toolbar>

      <v-card-text class="ma-0 pa-0">
        <DetailTableGrouped
          v-if="event"
          :items="items"
          :subtitle="overview.person"
        ></DetailTableGrouped>
        <DetailTable
          v-else
          :items="items"
          :subtitle="overview.person"
        ></DetailTable>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <ReportButton
          outlined
          text
          resource="report/personaccount"
          color="danger"
          :parameters="{
            id: overview.person.id,
            type: 'student',
            startDate: startDate,
            endDate: endDate,
          }"
          >PDF</ReportButton
        >

        <v-spacer /><v-btn text @click="dialog = false"
          >schliessen</v-btn
        ></v-card-actions
      >
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import CurrencyValue from "common/components/CurrencyValue";
import DateValue from "common/components/DateValue.vue";
import DateInput from "common/components/DateInput.vue";
import LookupValueInput from "common/components/LookupValueInput.vue";
import PersonItem from "common/components/PersonItem";
import ReportButton from "@/components/ReportButton";

import DetailTable from "./DetailTable.vue";
import DetailTableGrouped from "./DetailTableGrouped.vue";

import { today } from "common/utils/date.js";

export default defineComponent({
  components: {
    DateValue,
    DateInput,
    DetailTable,
    DetailTableGrouped,
    CurrencyValue,
    LookupValueInput,
    PersonItem,
    ReportButton,
  },
  props: ["id"],
  data: () => ({
    cantonal: 1, // achtung: 1 bedeutet «Eigene Kosten», 0 bedeutet «Beitrag Kanton» (weil v-tabs eine Bug mit werten hat und hier die indices verwendet werden)
    dialog: true,
    fullscreen: false,
    newDialog: false,
    newItem: {},
    overview: { person: {}, balance: 0 },
    items: [],
    loading: false,
    updated: false,
    saving: false,
    startDate: "",
    endDate: "",
    event: false,
  }),
  computed: {
    saveable() {
      return (
        this.newItem.amount &&
        this.newItem.date &&
        this.newItem.description &&
        this.newItem.type
      );
    },
  },
  watch: {
    event() {
      if (this.event) {
        localStorage.setItem("financeGroupedEvents", true);
      } else {
        localStorage.removeItem("financeGroupedEvents");
      }
    },
    startDate() {
      this.fetchData();
    },
    endDate() {
      this.fetchData();
    },
    cantonal() {
      this.fetchData();
    },
    newDialog() {
      this.newItem = {
        date: today(),
        description: "",
        amount: "",
        type: { id: 1 },
      };
    },
    dialog() {
      if (!this.dialog) {
        if (this.updated) {
          this.$emit("dataUpdated");
        }
        this.$router.push({
          name: "FinancePerson",
          query: this.$route.query,
        });
      }
    },
    id() {
      this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.overview = await this.apiGet({
        resource: "finance/personoverview",
        id: this.id,
      });
      this.items = await this.apiList({
        resource: "finance/persontransaction",
        query: `person=${this.id}&type=${
          this.cantonal === 0 ? "cantonal" : "own"
        }&startDate=${this.startDate}&endDate=${this.endDate}`,
      });
      this.loading = false;
    },

    async saveNew() {
      const data = {
        ...this.newItem,
        person: this.overview.person.id,
      };
      this.saving = true;
      await this.apiPost({
        resource: "finance/persontransaction",
        data: data,
        onSuccess: () => {
          this.$root.showSuccess("Transaktion wurde übernommen");
          this.fetchData();
          this.updated = true;
          this.newDialog = false;
        },
      });
      this.saving = false;
    },
  },

  async created() {
    this.event = localStorage.getItem("financeGroupedEvents");
    this.fetchData();
  },
});
</script>
