<template>
  <v-data-table
    :headers="headers"
    :items="itemsGrouped"
    disable-sort
    hide-default-footer
    disable-pagination
    show-expand
    item-key="event"
    @click:row="clickRow"
    :expanded.sync="expanded"
    :item-class="(item) => (item.items.length > 1 ? 'clickable' : '')"
  >
    <template v-slot:item.date="{ item }">
      <DateSpan :value="item.items.map((el) => el.date)"></DateSpan>
    </template>
    <template v-slot:item.text="{ item }">
      <strong>{{ item.event }}</strong>
      <br />{{
        item.items.length == 1
          ? item.items[0].description
          : item.items.length + " Rechnungen"
      }}
    </template>
    <template v-slot:item.invoiceNumber="{ item }">
      <span v-if="item.items.length == 1">{{
        item.items[0].invoiceNumber
      }}</span>
    </template>
    <template v-slot:item.amount="{ item }">
      <CurrencyValue :value="item.total" />
    </template>
    <template v-slot:item.creator="{ item }">
      {{ [...new Set(item.items.map((el) => el.creator.code))].join(", ") }}
    </template>
    <template v-slot:expanded-item="{ item }">
      <td>
        <p class="my-2" v-for="i in item.items" :key="i.id">
          <DateValue :value="i.date" />
        </p>
      </td>
      <td>
        <p class="my-2" v-for="i in item.items" :key="i.id">
          {{ i.description }}
        </p>
      </td>

      <td>
        <p class="my-2" v-for="i in item.items" :key="i.id">
          {{ i.invoiceNumber }}
        </p>
      </td>
      <td>
        <p class="my-2" v-for="i in item.items" :key="i.id">
          {{ i.creator.code }}
        </p>
      </td>
      <td>
        <p class="my-2 text-right" v-for="i in item.items" :key="i.id">
          <CurrencyValue :value="i.amount" />
        </p>
      </td>
    </template>
    <template v-slot:item.data-table-expand="{ isExpanded, item }">
      <v-icon v-if="item.items.length > 1">{{
        isExpanded ? "mdi-chevron-up" : "mdi-chevron-down"
      }}</v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { defineComponent } from "vue";
import CurrencyValue from "common/components/CurrencyValue";
import DateSpan from "common/components/DateSpan.vue";
import DateValue from "common/components/DateValue.vue";
import LookupValueInput from "common/components/LookupValueInput.vue";
import PersonItem from "common/components/PersonItem";

export default defineComponent({
  components: {
    CurrencyValue,
    DateSpan,
    DateValue,
    LookupValueInput,
    PersonItem,
  },
  props: ["items", "subtitle"],
  data: () => ({
    expanded: [],
    headers: [
      { text: "Datum", value: "date" },
      {
        text: "Beschreibung",
        value: "text",
        csv: [
          { text: "Anlass", value: "event" },
          { text: "Beschreibung", value: "description" },
        ],
      },
      { text: "Referenz", value: "invoiceNumber" },
      { text: "Person", value: "creator" },
      { text: "Betrag", value: "amount", align: "end" },
      { text: "", value: "data-table-expand" },
    ],
  }),
  computed: {
    itemsGrouped() {
      const results = [];
      for (const item of this.items) {
        let entry = results.find((el) => el.event === item.event);
        if (!entry) {
          entry = {
            event: item.event,
            total: 0,
            items: [],
          };
          results.push(entry);
        }
        entry.items.push(item);
        entry.total += item.amount;
      }
      return results.sort((a, b) => a.event.localeCompare(b.event));
    },
  },
  methods: {
    clickRow(item, event) {
      if (item.items.length == 1) {
        return;
      }
      if (event.isExpanded) {
        const indexExpanded = this.expanded.findIndex((i) => i === item);
        this.expanded.splice(indexExpanded, 1);
      } else {
        this.expanded.push(item);
      }
    },
  },
});
</script>
