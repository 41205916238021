<template>
  <v-data-table
    :headers="headers"
    :items="items"
    disable-sort
    hide-default-footer
    disable-pagination
  >
    <template v-slot:item.date="{ item }">
      <DateValue :value="item.date" />
    </template>
    <template v-slot:item.text="{ item }">
      <strong>{{ item.event }}</strong>
      <br />{{ item.description }}
    </template>
    <template v-slot:item.amount="{ item }">
      <CurrencyValue :value="item.amount" />
    </template>
    <template v-slot:item.balance="{ item }">
      <CurrencyValue :value="item.balance" />
    </template>
    <template v-slot:item.creator="{ item }">
      <PersonItem small :value="item.creator" />
    </template>
    <template v-slot:footer>
      <div class="pa-3">
        <CsvCopy
          block
          outlined
          :headers="headers"
          :items="items"
          title="Personenkonto"
          :subtitle="subtitle"
          >als CSV kopieren</CsvCopy
        >
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { defineComponent } from "vue";
import CurrencyValue from "common/components/CurrencyValue";
import CsvCopy from "common/components/CsvCopy.vue";
import DateValue from "common/components/DateValue.vue";
import LookupValueInput from "common/components/LookupValueInput.vue";
import PersonItem from "common/components/PersonItem";

export default defineComponent({
  components: {
    DateValue,
    CurrencyValue,
    CsvCopy,
    LookupValueInput,
    PersonItem,
  },
  props: ["items", "subtitle"],
  data: () => ({
    headers: [
      { text: "Datum", value: "date" },
      {
        text: "Beschreibung",
        value: "text",
        csv: [
          { text: "Anlass", value: "event" },
          { text: "Beschreibung", value: "description" },
        ],
      },
      { text: "Person", value: "creator" },
      { text: "Referenz", value: "invoiceNumber" },
      { text: "Betrag", value: "amount", align: "end" },
      { text: "Saldo", value: "balance", align: "end" },
    ],
  }),
});
</script>
